import { Search as ElementsSearch, useGetNodes, useGetWorkspace } from '@stoplight/elements-dev-portal';
import * as React from 'react';

export type SearchProps = {
  projectIds: string[];
};

export const Search = ({ projectIds }) => {
  const [search, setSearch] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const { data } = useGetNodes({
    search,
    projectIds,
  });
  const { data: workspace } = useGetWorkspace({
    projectIds,
  });

  const handleClose = () => {
    setOpen(false);
    setSearch('');
  };

  const handleClick = (data) => {
    const subdir = data.project_slug === 'apis' ? 'developers' : 'knowledge-base'
    window.location.href = `${window.origin}/docs/${subdir}/${data.slug}`;
  };

  return (
    <>
      <a
        className="topnav__search"
        onClick={() => { setOpen(true); return false } }
        href="#"
      >
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.41667 11.0833C8.994 11.0833 11.0833 8.994 11.0833 6.41667C11.0833 3.83934 8.994 1.75 6.41667 1.75C3.83934 1.75 1.75 3.83934 1.75 6.41667C1.75 8.994 3.83934 11.0833 6.41667 11.0833Z" stroke="#262626" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12.25 12.2499L9.71252 9.7124" stroke="#262626" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <span>
          Search docs & API reference...
        </span>
      </a>
      <ElementsSearch
        search={search}
        onSearch={setSearch}
        onClick={handleClick}
        onClose={handleClose}
        isOpen={open}
        searchResults={data}
      />
    </>
  );
};