import { DevPortalProvider } from '@stoplight/elements-dev-portal';
import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Navigation } from './components/Navigation';
import { NotFound } from './components/NotFound';
import { StoplightProjectDocs, StoplightProjectDocsDev } from './components/StoplightProject';

import '@stoplight/elements-dev-portal/styles.min.css';
import './App.css';
import './index.css';

class App extends Component {
  render() {
    return (
      <DevPortalProvider>
        <BrowserRouter>
          <div className="app-container">
            <header>
              <Navigation />
            </header>
            <main className="main-content">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/docs/knowledge-base" />
                </Route>
                <Route exact path="/docs">
                  <Redirect to="/docs/knowledge-base" />
                </Route>
                <Route path="/docs/knowledge-base" component={StoplightProjectDocs} />
                <Route path="/docs/developers" component={StoplightProjectDocsDev} />
                <Route component={NotFound} />
              </Switch>
            </main>
          </div>
        </BrowserRouter>
      </DevPortalProvider>
    );
  }
}

export default App;